import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';

import { AuthContext } from './context/Context';
import AuthService from './services/AuthService';
import PrivateRoute from '../src/routes/privateRoute';
import Callback from './auth/Callback';

import Landing from './views/pages/Landing';
import GlobalContext from './context/GlobalContext';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Page404 = React.lazy(() => import('./views/pages/Landing'));

const App = (props) => {
  const auth = new AuthService(props.history);

  return (
    <AuthContext.Provider value={auth}>
      <ToastContainer />
      <GlobalContext>
        <Router>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route path="/admin" exact render={(props) => <Callback auth={auth} {...props} />} />
              <Route path="/landing" exact render={(props) => <Landing auth={auth} {...props} />} />
              <PrivateRoute path="/" name="Home" component={DefaultLayout} />
              <Route render={(props) => <Page404 {...props} />} />
            </Switch>
          </React.Suspense>
        </Router>
      </GlobalContext>
    </AuthContext.Provider>
  );
};

App.propTypes = {
  history: PropTypes.object.isRequired,
};
export default App;
