import React from 'react';
import PropTypes from 'prop-types';

import { CSpinner } from '@coreui/react';

import { authConfig } from '../../config/authConfig';

const Landing = (props) => {
  const { auth } = props;
  auth.completeLogout();
  window.location.replace(authConfig.client_url);
  window.addEventListener('popstate', () => {
    auth.history.go(1);
  });

  return <CSpinner></CSpinner>;
};
Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
export default Landing;
