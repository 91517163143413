import { authConfig } from '../config/authConfig';
import { UserManager } from 'oidc-client';

export default class AuthService {
  UserManager;

  constructor(history) {
    this.history = history;
    this.UserManager = new UserManager({
      ...authConfig,
    });
  }
  loginState = false;

  login = () => {
    this.UserManager.signinRedirect();
  };

  isLoggedIn = () => {
    return this.UserManager.getUser().then((user) => {
      const currentUser = !!user && !user.expired;
      return currentUser;
    });
  };
  completeLogin = () => {
    this.UserManager.signinRedirectCallback()
      .then((res) => {
        console.log('response is :', res);
      })
      .catch((e) => {
        console.log('error is :', e);
      });
  };

  handleAuthentication = () => {
    return new Promise((resolve, reject) => {
      this.get_user()
        .then((user) => {
          if (!!user && !user.expired) {
            this.setSession(user);
            this.loginState = true;
            resolve(user);
          } else {
            reject(null);
          }
        })
        .catch((err) => {
          this.UserManager.signinRedirect();
          reject(err);
        });
    });
  };

  get_user = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }

    return user;
  };

  setSession = (userResult) => {
    localStorage.setItem('access_token', userResult.access_token);
    localStorage.setItem('id_token', userResult.id_token);
    localStorage.setItem('expires_at', userResult.expires_at);
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`,
      ),
    );

    if (!!oidcStorage && !!oidcStorage.access_token) {
      this.loginState = true;
      return !!oidcStorage && !!oidcStorage.access_token;
    } else {
      this.loginState = false;
      return !!oidcStorage && !!oidcStorage.access_token;
    }
  };

  logout = async () => {
    this.UserManager = new UserManager({
      ...authConfig,
    });
    await this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token'),
      state: 'abcdefg',
    });
    localStorage.clear();
    sessionStorage.clear();
    this.UserManager.clearStaleState();
  };

  completeLogout = () => {
    this.UserManager.signoutRedirectCallback();
  };
}
