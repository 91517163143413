import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { CSpinner } from '@coreui/react';

const Callback = (props) => {
  const { auth, location, history } = props;

  useEffect(() => {
    async function getUser() {
      if (/access_token|id_token|error/.test(location.hash)) {
        await auth.handleAuthentication();
        history.push('/dashboard');
        history.go(1);
      } else {
        // throw new Error('Invalid callback URL');
      }
    }
    getUser();
  }, []);

  return <CSpinner />;
};

Callback.propTypes = {
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Callback;
