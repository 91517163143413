export const authConfig = {
  authority: process.env.REACT_APP_AUTH_URL,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
  post_logout_redirect_uri: process.env.REACT_APP_IDENTITYSERVER_LOGOUT_REDIRECT_URL,
  audience: process.env.REACT_APP_AUDIENCE,
  response_type: 'id_token token',
  automaticSilentRenew: false,
  loadUserInfo: false,
  scope: process.env.REACT_APP_SCOPE,
  state: 'dskjqmoiqQdjnJHOnGn',
  client_url: process.env.REACT_APP_CLIENT_URL,
};
