import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthService from '../services/AuthService';

const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;

  const auth = new AuthService(props.history);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.isAuthenticated()) {
          return auth.login();
        } else {
          return <Component auth={auth} {...props} />;
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.object,
  history: PropTypes.object,
};

export default PrivateRoute;
