import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SearchContext } from './Context';

const GlobalContext = (props) => {
  const [searchQuery, setSearchQuery] = useState({});

  return (
    <SearchContext.Provider value={{ searchQuery: searchQuery, setSearchQuery: setSearchQuery }}>
      {props.children}
    </SearchContext.Provider>
  );
};

GlobalContext.propTypes = {
  children: PropTypes.object,
};

export default GlobalContext;
