import { createContext } from 'react';

export const AuthContext = createContext({
  login: () => ({}),
  completeLogin: () => ({}),
  logout: () => ({}),
  completeLogout: () => ({}),
  isAuthenticated: () => ({}),
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
});

export const SearchContext = createContext({
  searchQuery: undefined,
  setSearchQuery: () => {},
});
